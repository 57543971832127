import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main skills",
        tab2 = "Certifications",
        tab3 = "Experience",
        tab4 = "Education & Certification";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">Extensive experience with full-cycle or building enterprise software: <span>Over the past <span style={{ textDecorationLine: "underline" }}>6-7 years</span>, I've honed my expertise in designing seamless and intuitive user experiences, coupled with a proficiency in translating those designs into efficient and elegant code. My skill set encompasses a deep understanding of user-centered design principles, allowing me to create interfaces that not only captivate users visually but also enhance their overall interaction with digital platforms. Through a combination of creativity and technical proficiency, I have successfully crafted user experiences that prioritize usability, accessibility, and aesthetic appeal.</span></a>
                                                </li>
                                                <li>
                                                    <a href="/service">Building startup concepts/apps: <span>Over the past few years, I've undertaken the formidable task of independently conceptualizing, building, scaling, and deploying large-scale enterprise applications, showcasing my versatility as a self-driven developer. Through the development of startup concepts, I've navigated the entire lifecycle of application development, demonstrating not only technical proficiency but also a comprehensive understanding of the business landscape. My journey has equipped me with the ability to architect robust systems that can handle the complexities of enterprise-level demands. From the inception of innovative ideas to the practical implementation and subsequent scaling, I've adeptly managed each phase, fostering a deep appreciation for the intricate interplay between technology and business strategy</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">W3Schools JavaScript <span> Certification</span></a> 2020
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Front-End Developer/Engineer<span> - Shimbly</span></a> 2020-2021
                                               </li>
                                               <li>
                                                   <a href="/service">Freelancing Full-Stack Dev<span> - Upwork.com/In-Person</span></a> 2016-2023
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Associates<span> - Asheville-Buncombe Community, North Carolina</span></a> 2015
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;