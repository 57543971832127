import React, { Component, Fragment } from "react";
import PageHelmet from "../../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../component/header/Header";
import Footer from "../../../component/footer/Footer";
import { CopyBlock, dracula } from 'react-code-blocks';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';

class BlogDetailsSetupMERNApp extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Blog Details' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Setting up a MERN stack <br /> full-stack application</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />December, 4th 2023</li>
                                        <li><FiUser />Jeremy Blong</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />Like</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>Welcome to this comprehensive tutorial where we embark on a journey to master the art of setting up a MERN (MongoDB, Express.js, React, Node.js) stack for a full-stack application. In the realm of web development, the MERN stack has emerged as a powerful and popular choice, seamlessly integrating the strengths of MongoDB for the database, Express.js for the server, React for the front end, and Node.js as the runtime environment.
                                            <br />
                                            <br />
                                        In the chapters ahead, we'll delve into the intricacies of constructing a robust MERN stack application, covering every fundamental aspect from establishing the server directory to configuring the database connection settings. We'll demystify essential files such as .env and default.json, shedding light on their roles in the overall architecture. These configuration files play a pivotal role in managing environmental variables and providing a structured approach to your project settings.
                                            <br />
                                            <br />
                                        Our journey will extend to the frontend, where we will generate a React app and configure its basic settings. The React library, renowned for its declarative and efficient nature, will serve as the backbone of our application's user interface. Understanding how to set up and configure the React app is crucial for ensuring a smooth development process and a seamless user experience.
                                            <br />
                                            <br />
                                        So, whether you're a seasoned developer looking to expand your skill set or a newcomer eager to explore the world of full-stack development, join us as we unravel the layers of the MERN stack and guide you through the steps of building a powerful, dynamic, and responsive web application. Get ready to immerse yourself in the intricacies of server-side development, database management, and frontend design as we embark on this enlightening journey together.</p>
                                        <hr />
                                        <h5>First, We will need to setup the project structure using the below commands:</h5>
                                        {/* <div className="thumbnail"> */}
                                            {/* <img src={require("../../../assets/coding4.jpg")} alt="Blog Images"/> */}
                                            <CodeMirror value={`mkdir ourproject && cd ourproject && mkdir server`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <p style={{ marginTop: 22.5 }}>We now need to create the react project now that we've created our main folder and the server directory inside the main newly created folder named 'ourproject'</p>
                                            <CodeMirror value={`npx create-react-app client`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <p style={{ marginTop: 25 }} className="lead">The command npx create-react-app client is a streamlined way to bootstrap a new React application using Create React App, a popular tool that sets up a React project with sensible defaults and a well-organized directory structure. When executed, npx create-react-app fetches the latest version of Create React App from the npm registry and uses it to create a new React project named "client." This process automates the setup of essential files, development dependencies, and a basic project structure, sparing developers the manual configuration work. The "client" argument specifies the name of the project directory; however, you can replace "client" with your preferred project name. This command not only jumpstarts your React project but also ensures that you adhere to best practices, enabling you to focus on building your application's features rather than spending time on initial setup and configuration.</p>
                                        {/* </div> */}
                                        <p className="mt--40">This has created our react client-side application as well as the serverside directory.</p>
                                        <p style={{ fontWeight: "bold" }}>Now we will 'cd' into the server directory and install the required dependencies!</p>
                                        <CodeMirror value={`cd server && npm i express body-parser cors xss-clean helmet express-mongo-sanitize express-rate-limit connect-flash express-session path mongodb nodemon config mongoose`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                        <p style={{ marginTop: 22.5 }}>In the Node.js and React ecosystem, the command 'npm install' is a linchpin in the process of managing dependencies, constituting a vital step in project setup. This command, which stands for "Node Package Manager install," is instrumental in fetching and installing the various external packages and libraries that a project relies on. When executed, 'npm install' reads the 'package.json' file in the project directory, which meticulously lists all dependencies and their versions. Subsequently, it downloads and installs these dependencies, ensuring that the project environment is equipped with the necessary tools, modules, and frameworks to function seamlessly. </p>
                                        <blockquote className="rn-blog-quote">Full-stack development is the art of orchestrating pixels on the front end and bytes on the back end, uniting creativity and logic into a seamless symphony of software craftsmanship.</blockquote>
                                        <p>Now we'll need to add the 'server' logic so we can run our backend runtime environment!</p>
                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                        <CodeMirror value={`const express = require("express");
const app = express();
const bodyParser = require('body-parser');
const cors = require("cors");
const xss = require('xss-clean');
const helmet = require("helmet");
const mongoSanitize = require('express-mongo-sanitize');
const rateLimit = require("express-rate-limit");
const { Connection } = require("./mongoUtil.js");
const flash = require('connect-flash');
const session = require('express-session');
const path = require("path");
// setup port logic
const PORT = process.env.PORT || 8000;

// Use helmet middleware with contentSecurityPolicy
app.use(
  helmet.contentSecurityPolicy({
    directives: {
      defaultSrc: ["'self'"],
      scriptSrc: ["'self'", "'unsafe-inline'"]
    },
  })
);  

app.use(helmet({ contentSecurityPolicy: false }));

app.use(bodyParser.json({
    limit: "500mb"
}));
app.use(bodyParser.urlencoded({
    limit: "500mb",
    extended: false
}));

const corsOptions = {
    origin: "*",
    credentials: true,
  optionSuccessStatus: 200
};

app.use(flash());

app.use(session({ 
    cookie: { 
        maxAge: 60000 
    }, 
    secret: 'woot',
    resave: false, 
    saveUninitialized: false
}));
  
app.use(cors(corsOptions));

const limiter = rateLimit({
  limit: 100, 
    standardHeaders: 'draft-7',
    legacyHeaders: false, 
  max: 100,// max requests
  windowMs: 60 * 60 * 1000 * 1000, // remove the last 1000 for production
  message: 'Too many requests' // message to send
}); 

app.use(xss());
app.use(helmet());
app.use(mongoSanitize());
app.use(limiter);

app.use(cors({ origin: '*' }));

app.use((req, res, next) => {
    res.setHeader("Access-Control-Allow-Origin", "*");
    res.setHeader("Access-Control-Allow-Credentials", true);
    res.setHeader('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
  res.setHeader('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
    next();
});

app.get("/", (req, res) => {
  res.set("Content-Security-Policy", "default-src *; style-src 'self' http://* 'unsafe-inline'; script-src 'self' http://* 'unsafe-inline' 'unsafe-eval'").sendFile(path.join(__dirname, '../client/build', 'index.html'));
})

// routes go here...

Connection.open();

app.use(express.static(path.join(__dirname, '../client/build')));

// Handle React routing, return all requests to React app
app.get('*', (req, res) => {
  console.log("hit *", __dirname);

  res.set("Content-Security-Policy", "default-src *; style-src 'self' http://* 'unsafe-inline'; script-src 'self' http://* 'unsafe-inline' 'unsafe-eval'").sendFile(path.join(__dirname, '../client/build', 'index.html'));
});

if (process.env.NODE_ENV === 'production') {
  // Serve any static files
  app.use(express.static(path.join(__dirname, '../client/build')));

  // Handle React routing, return all requests to React app
  app.get('*', (req, res) => {
    res.sendFile(path.join(__dirname, '../client/build', 'index.html'));
  });
}

app.listen(PORT, () => {
    console.log("app listening on port :", PORT);
});`} height="800px" width="500px" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <div style={{ paddingLeft: 15 }} className="content">
                                                <p>The provided code is a Node.js/Express server setup with several middleware and configurations to enhance security, handle HTTP requests, and serve a React front end. Here's a breakdown of the key components and functionalities:</p>
                                                <h4 className="title">Imports & Port configuration:</h4>
                                                <ul className="list-style">
                                                    <li>The code begins by importing necessary Node.js modules and external packages, such as Express, body-parser, cors, xss-clean, helmet, express-mongo-sanitize, express-rate-limit, connect-flash, express-session, and path.</li>
                                                    <li>The code sets up the server to listen on a specified port, either provided by the environment variable process.env.PORT or defaulting to port 8000.</li>
                                                </ul>
                                                <h4 className="title">Middleware Setup:</h4>
                                                <ul className="list-style">
                                                    <li><strong>Helmet</strong>: Adds security headers, including Content Security Policy (CSP).</li>
                                                    <li><strong>Body-parser</strong>: Parses incoming request bodies in JSON and URL-encoded formats.</li>
                                                    <li><strong>cors</strong>: Enables Cross-Origin Resource Sharing with specified options.</li>
                                                    <li><strong>XSS-Clean</strong>: Protects against Cross-Site Scripting (XSS) attacks.</li>
                                                    <li><strong>Mongo-sanitize</strong>: Prevents MongoDB query injection.</li>
                                                    <li><strong>Rate Limit</strong>: Limits the number of requests per IP within a specified time frame..</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <p className="mt--25 mt_sm--5">In the upcoming stages of our tutorial, we will delve into configuring essential settings for MongoDB, a NoSQL database that plays a pivotal role in the MERN stack. Central to this setup is the default.json file, where key configurations for MongoDB will be defined. This file serves as a convenient repository for environment-specific variables, such as database connection URLs, credentials, and other settings crucial for seamless interaction between the Node.js server and the MongoDB database. By carefully configuring the default.json file, we ensure a well-organized and secure environment for our MERN stack application. From specifying database connection details to defining environmental variables, this step is integral to establishing a robust backend foundation, ultimately contributing to the overall stability and scalability of our full-stack project</p>
                                        <div className="video-wrapper position-relative mb--40">
                                            <div className="thumbnail">
                                                <img src={require("../../../assets/server.jpg")} alt="Blog Images"/>
                                            </div>
                                        </div>
                                        <p style={{ fontWeight: "bold" }}>Now we'll need to setup the config folder with a 'default.json' file for our private/secret credentials and the db.js file!</p>
                                        <CodeMirror value={`cd server && mkdir config && cd config`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                        <p style={{ marginTop: 25, fontWeight: "bold" }} className="lead">Now we need to create both our db.js and default.json file's:</p>
                                        <CodeMirror value={`touch default.json db.js`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                        <p style={{ marginTop: 25, fontWeight: "bold" }} className="lead">Now add this to your 'db.js' file, this will connect us to the MongoDB database in our server.js file or any other related files that need to communicate with the DB.</p>
                                        <CodeMirror value={`const MongoClient = require('mongodb').MongoClient;
const config = require("config");
const url = config.get("mongoURI");
const mongoose = require("mongoose");

class Connection {
  static async open() {
      if (this.db) {
        return this.db;
      };
      await mongoose.connect(url, { useNewUrlParser: true });
      
      this.db = await MongoClient.connect(this.url, this.options);

      console.log("connected at end - DB...", this.db);

      return this.db;
  }
}

Connection.db = null;
Connection.url = url;
Connection.options = {
    useNewUrlParser:    true,
    useUnifiedTopology: true,
}

module.exports = { Connection }`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                        <p style={{ marginTop: 25, fontWeight: "bold" }} className="lead">Now, onto the default.json configuration file which will store all of our secret API keys and credentials:</p>
                                        <CodeMirror value={`{
    "mongoURI": "your-mongodb-connection-string-here"
}`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                        <p style={{ marginTop: 25, fontWeight: "bold" }} className="lead">Now lets spin up the client-side and server-side applications through adding this to our package.json in our server directory (first we'll install the concurrently package - NPM)...:</p>
                                        <CodeMirror value={`npm install concurrently --save-dev`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                        <p style={{ marginTop: 25, fontWeight: "bold" }} className="lead">Then we'll add the script to our 'start' script in our server layer package.json file:</p>
                                        <CodeMirror value={`"scripts": {
  "start": "concurrently \"nodemon server.js\" \"cd && client && npm run start\""
  // ... other scripts
}`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                        <p style={{ marginTop: 25, fontWeight: "bold" }} className="lead">Then we'll add the script to our 'start' script in our server layer package.json file:</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start BLog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h3 className="title mb--40 fontWeight500">Leave a Reply</h3>
                                    <form action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn">
                                                    <Link className="rn-button-style--2 btn-solid" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default BlogDetailsSetupMERNApp;