import React, { Component } from "react";
import ContactForm from "./ContactForm";

class ContactTwo extends Component {
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Contact Us.</h2>
                                <p className="description">
                                    Welcome to our 'Contact Me' page, the gateway to seamless communication! Whether you have inquiries, collaboration proposals, or just want to say hello, this form is your direct line to connect with me. Your thoughts matter, and I'm eager to hear from you. Simply fill out the form below, and I will get back to you promptly. Let's start a conversation and explore the possibilities together. Your feedback and questions drive our commitment to excellence. Thank you for reaching out!
                                </p>
                            </div>
                            <div className="form-wrapper">
                                <ContactForm />
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={require("../../assets/meeeeee.jpg")} alt="trydo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;