import React, { Component } from "react";
import PageHelmet from "../../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../component/header/Header";
import Footer from "../../../component/footer/Footer";
import "./styles.css";
import { Link } from "react-router-dom";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class PortfolioDetailsTwoMakeNDate extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Portfolio Details' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image--1234"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">MakeNDate Dating Platform (Mobile App)</h2>
                                    <p>
                                        I developed a cutting-edge cybersecurity freelancing platform that streamlines service exchanges through contract hires. Specializing in penetration testing, the platform empowers companies to fortify their network security and safeguard web/mobile applications. By facilitating the collaboration of skilled professionals, it enables businesses to enhance their online presence while ensuring the robust security of their digital services. This innovative platform serves as a bridge between freelance cybersecurity experts and organizations, fostering a secure digital landscape for the evolving online realm.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>MakeNDate Dating</h2>
                                        <p className="subtitle">
                                            🚀 Introducing Makendate, the ultimate dating mobile app designed to redefine connections! 📱 Experience a world where dating meets innovation, featuring live in-person meetups, seamless video calling, and engaging messaging, all within the vibrant social landscape of Makendate.
                                            <br />
                                            <br />
                                            💑 Dive into a realm of possibilities as Makendate goes beyond traditional dating apps. Our platform isn't just about swipes; it's a community-driven experience. Connect with like-minded individuals in real-life settings through our live in-person meetups, fostering genuine connections beyond the digital sphere.
                                            <br />
                                            <br />
                                            🎥 Break the barriers with our cutting-edge video calling feature, enabling face-to-face interactions regardless of distance. Whether it's a casual chat or a virtual date night, Makendate brings people closer in the most immersive way possible.
                                            <br />
                                            <br />
                                            💬 Stay engaged with our robust messaging system, designed for fluid and meaningful conversations. But Makendate isn't just about romance; it's a hub for mentorship, networking, and friendships. Connect with mentors who share your interests or explore new opportunities in a dynamic social environment.
                                            <br />
                                            <br />
                                            👗 Elevate your experience with the unique buy-sell clothing feature. Showcase your style, discover fashion trends, and even make a connection through a shared love for fashion. Makendate is more than just a dating app; it's a lifestyle platform where connections transcend beyond the virtual world.
                                            <br />
                                            <br />
                                            📢 Join live-streams and events that add a social flair to your dating journey. From interactive discussions to themed gatherings, Makendate's live-streams amplify the sense of community, making every interaction memorable.
                                            <br />
                                            <br />
                                            Embark on a journey of connection, exploration, and innovation with Makendate – where dating meets the social future! 🌐✨
                                            </p>
                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Type</span>
                                                <h4>Full-Stack JavaScript (MERN Stack)</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Types</span>
                                                <h4>Mobile/App</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Technologies</span>
                                                <h4>Express.JS, Node.JS, React-Native, MongoDB, WebSockets, AWS S3, API's/REST, RTMP, Etc...</h4>
                                            </div>
                                        </div>

                                        <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val , i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src={require("../../../assets/6.png")} alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='2nZA6yGR8Ng' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
{/*                                         
                                        <div className="thumb mb--30">
                                            <img src={require("../../../assets/cyber1.png")}  alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--30">
                                            <img src={require("../../../assets/cyber3.png")}  alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--30">
                                            <img src={require("../../../assets/cyber5.png")}  alt="Portfolio Images"/>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Other Related Work</span>
                                    <h2>More Of My Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <Link to="/portfolio-details-cyber">
                                            <img src={require("../../../assets/cyber1.png")} alt="Portfolio-images"/>
                                        </Link>
                                    </div>
                                    <div className="inner">
                                        <h4><Link to="/portfolio-details-cyber">The Hacker Marketplace</Link></h4>
                                        <span className="category">Web Application - Full-Stack JavaScript</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <Link to="/ad-truck/app/porfolio">
                                            <img src={require("../../../assets/adtruck.png")} alt="Portfolio-images"/>
                                        </Link>
                                    </div>
                                    <div className="inner">
                                        <h4><Link to="/ad-truck/app/porfolio">Ad-Truck Semi-Advertising</Link></h4>
                                        <span className="category">Mobile Application - Full-Stack JavaScript</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default PortfolioDetailsTwoMakeNDate;
