const BlogContent = [
    {
      "images": "01",
      "title": "Setting up MERN App: A Comprehensive Guide to setting up dev environment/codebase.",
      "category": "Full-Stack JS Tutorial",
      "link": "/setting-up-mern-application",
      "image": require("../../assets/coding1.jpg")
    },
    {
      "images": "02",
      "title": "Deploying Your MERN Stack Mastery: A Step-by-Step Guide to Uploading Your Project to a Digital Ocean Droplet",
      "category": "Full-Stack Deployment",
      "link": "/deploy-mern-stack-app-digital-ocean-tutorial",
      "image": require("../../assets/coding2.jpg")
    },
    {
      "images": "03",
      "title": "MERN Magic: Unlocking the Secrets of Full-Stack JavaScript Development",
      "category": "Full-Stack Dev",
      "link": "/",
      "image": require("../../assets/coding3.jpg")
    },
    {
      "images": "04",
      "title": "Crafting Dynamic Interfaces: MERN Stack Unleashed",
      "category": "Development",
      "link": "/",
      "image": require("../../assets/coding1.jpg")
    },
    {
      "images": "05",
      "title": "The MERN Blueprint: A Developer's Guide to Full-Stack JavaScript Brilliance",
      "category": "Development",
      "link": "/",
      "image": require("../../assets/coding1.jpg")
    },
    {
      "images": "06",
      "title": "Beyond Basics: Advanced Techniques in MERN Stack Development",
      "category": "Development",
      "link": "/",
      "image": require("../../assets/coding1.jpg")
    }
]

export default BlogContent;