import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import "./styles.css";
import { Link } from "react-router-dom";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class PortfolioDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Portfolio Details' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image--123"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Cybersecurity contract-hire security marketplace (website)</h2>
                                    <p>
                                        I developed a cutting-edge cybersecurity freelancing platform that streamlines service exchanges through contract hires. Specializing in penetration testing, the platform empowers companies to fortify their network security and safeguard web/mobile applications. By facilitating the collaboration of skilled professionals, it enables businesses to enhance their online presence while ensuring the robust security of their digital services. This innovative platform serves as a bridge between freelance cybersecurity experts and organizations, fostering a secure digital landscape for the evolving online realm.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>The Hacker Marketplace</h2>
                                        <p className="subtitle">Revolutionizing Ethical Hacking Freelancing for Enhanced Digital Security through an innovative & expansive marketplace environment!</p>
                                        <p>
                                            Introducing a revolutionary freelancing platform designed to reshape the landscape of cybersecurity contract hires, my creation merges the convenience of Upwork's bidding-style contracts with the critical field of ethical hacking. Operating on the principle of user-friendly design akin to Upwork, our platform allows businesses to seamlessly connect with skilled penetration testers for one-off engagements, providing a cost-effective solution to enhance network and application security without the burden of long-term commitments.
                                            <br />
                                            <br />
                                            At the heart of this platform is the unique 'bidding' mechanism, where freelance cybersecurity professionals can browse and bid on listings posted by companies seeking penetration testing services. This approach ensures a competitive yet transparent process, enabling businesses to choose from a pool of qualified experts who align with their specific security needs. This flexible model caters to diverse budgets, making it an affordable avenue for companies of all sizes to bolster their cybersecurity defenses.
                                            <br />
                                            <br />
                                            Navigating the hiring process is simplified through an intuitive in-app experience. Companies can effortlessly post listings detailing their security requirements, and freelancers can submit competitive bids, creating a streamlined environment for collaboration. The platform handles all aspects of communication, project management, and payment transactions, offering a comprehensive solution that fosters trust and transparency between clients and freelancers.
                                            <br />
                                            <br />
                                            The platform's commitment to security extends beyond its services to the very infrastructure it operates on. Employing state-of-the-art encryption and stringent authentication measures, we prioritize the confidentiality and integrity of sensitive information exchanged on the platform. This dedication ensures that users can confidently engage in penetration testing projects, knowing that their data is shielded from potential threats.
                                            <br />
                                            <br />
                                            In essence, my cybersecurity freelancing platform stands as a game-changer, providing a cost-effective, efficient, and secure way for businesses to access ethical hackers and fortify their digital defenses. Whether you're a startup looking to validate your cybersecurity measures or an established enterprise seeking specialized testing, our platform offers a dynamic marketplace where expertise meets opportunity, ultimately elevating the security posture of businesses in the ever-evolving digital landscape.
                                        </p>
                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Type</span>
                                                <h4>Full-Stack JavaScript (MERN Stack)</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Types</span>
                                                <h4>Website/Web</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Technologies</span>
                                                <h4>Express.JS, Node.JS, React.JS, MongoDB, WebSockets, AWS S3, RTMP, Etc...</h4>
                                            </div>
                                        </div>

                                        <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val , i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src={require("../assets/cyber2.png")} alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='NoCi2i_6S3c' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src={require("../assets/cyber1.png")}  alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--30">
                                            <img src={require("../assets/cyber3.png")}  alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--30">
                                            <img src={require("../assets/cyber5.png")}  alt="Portfolio Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Other Related Work</span>
                                    <h2>More Of My Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <Link to="/portfolio-details-makendate">
                                            <img src={require("../assets/makendate.png")} alt="Portfolio-images"/>
                                        </Link>
                                    </div>
                                    <div className="inner">
                                        <h4><Link to="/portfolio-details-makendate">MakeNDate Dating</Link></h4>
                                        <span className="category">Mobile Application - Full-Stack JavaScript</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <Link to="/ad-truck/app/porfolio">
                                            <img src={require("../assets/adtruck.png")} alt="Portfolio-images"/>
                                        </Link>
                                    </div>
                                    <div className="inner">
                                        <h4><Link to="/ad-truck/app/porfolio">Ad-Truck Semi-Advertising</Link></h4>
                                        <span className="category">Mobile Application - Full-Stack JavaScript</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default PortfolioDetails;
