import React, { Component } from "react";
import ReactStars from 'react-stars';


class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="row">
                        <ReactStars
                            count={5}
                            value={5}
                            onChange={() => {}}
                            edit={false}
                            size={45}
                            color={'#FFD700'} 
                        />
                    </div>
                        <p className="leader">"Jeremy explained everything I had questions about in a manner that was very easy for me to comprehend. Succinct explainations, very helpful, A++ would recommend and would recommend/ hire again!
                    <br />
                    Jeremy took me from limping to functional, explaining the process the whole way. Excellent. Thanks again!"
                    </p>
                </div>
                <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="row">
                        <ReactStars
                            count={5}
                            value={5}
                            onChange={() => {}}
                            edit={false}
                            size={45}
                            color={'#FFD700'} 
                        />
                    </div>
                        <p className="leader">"Jeremy was incredibly professional and quick to help us solve our formatting issue. We would hire him again."
                    </p>
                </div>
                <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="row">
                        <ReactStars
                            count={5}
                            value={5}
                            onChange={() => {}}
                            edit={false}
                            size={45}
                            color={'#FFD700'} 
                        />
                    </div>
                        <p className="leader">"Jeremy was prompt and professional, being very communicative and delivered a good service"
                    </p>
                </div>
                <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="row">
                        <ReactStars
                            count={5}
                            value={5}
                            onChange={() => {}}
                            edit={false}
                            size={45}
                            color={'#FFD700'} 
                        />
                    </div>
                        <p className="leader">"This freelancer was tremendous, working diligently to complete the desired work in a reasonable timespan. He was very communicative and open about the development processes and timeline!"
                    </p>
                </div>
            </React.Fragment>
        )
    }
}
export default BrandTwo;