import React, { Component, Fragment } from "react";
import PageHelmet from "../../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../component/header/Header";
import Footer from "../../../component/footer/Footer";
import "./styles.css";
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';

class BlogDetailsDetailsDeployMERNStackDigitalOCeanDroplet extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Blog Details' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Deploying Your MERN Stack Mastery: <br /> Uploading Your Project to a Digital Ocean Droplet</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />December, 11th 2023</li>
                                        <li><FiUser />Jeremy Blong</li>
                                        <li><FiMessageCircle />0 Comments</li>
                                        <li><FiHeart />Like</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>
Welcome to the ultimate guide on taking your MERN (MongoDB, Express.js, React, Node.js) stack project to the next level by deploying it to a Digital Ocean droplet. If you've spent countless hours crafting a seamless full-stack JavaScript application and are ready to share it with the world, this blog is your go-to resource. We'll demystify the deployment process, making the transition from local development to a live server as smooth as possible.
<br />
<br />
Uploading a MERN stack project to a Digital Ocean droplet involves a series of well-defined steps, each playing a crucial role in ensuring your application runs efficiently in a production environment. From configuring your server to handling database connections and optimizing performance, we'll delve into the intricacies of every aspect. Whether you're a seasoned developer looking to expand your deployment skills or a newcomer eager to showcase your first project, this guide is tailored to empower you with the knowledge needed for successful deployment.
<br />
<br />
Get ready to unlock the potential of your MERN stack masterpiece as we navigate through the intricacies of deploying it to a Digital Ocean droplet. By the end of this journey, you'll not only have your application up and running on a live server but also gain valuable insights into the deployment practices that can elevate your development prowess. Let's dive into the world of deploying MERN stack projects and witness your code come to life on the web!</p>
                                        <hr />
                                        <h5>First, we will need to generate our SSH key(s) to access our droplet. First we'll need to generate our keys which I'll show below...</h5>
                                        {/* <div className="thumbnail"> */}
                                            {/* <img src={require("../../../assets/coding4.jpg")} alt="Blog Images"/> */}
                                            <p style={{ marginTop: 6.25, fontWeight: "bold" }} className="lead">Windows ~ Linux/Mac:</p>
                                            <ul>
                                                <li>Open PowerShell or Command Prompt.</li>
                                                <li>Run the following command to generate a new SSH key pair:</li>
                                            </ul>
                                            <CodeMirror value={`ssh-keygen -t rsa -b 2048`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <p style={{ marginTop: 15.25, fontWeight: "bold" }} className="lead">Save this copied rsa key somewhere <em style={{ textDecorationLine: "underline" }}>safe***</em> in the meantime - you should also store it for longer term recovery if need be:</p>
                                            <p>Open the terminal on your machine and run the following command:</p>
                                            <p style={{ fontWeight: "bold" }} className="lead">Now 'copy' the RSA/SSH key with the following command in the terminal:</p>
                                            <CodeMirror value={`cat ~/.ssh/id_rsa.pub`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <img src={require("../../../assets/uploadDOTutorial/blog1.png")} className="image-blog" />
                                            <p style={{ marginTop: 6.25 }} className="lead">Follow the process to create a digital ocean droplet, its fairly straight forward. Pick a server relative to your location and choose the tier that fits your needs best regarding SSD, RAM, CPU's, etc...</p>
                                            <p style={{ fontWeight: "bold" }} className="lead">Assuming you have your droplet created & your new droplet ip address, lets now ssh into our droplet with the following command:</p>
                                            <CodeMirror value={`ssh root@ip-address`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <p style={{ marginTop: 15 }} className="lead">Now run the following commands to install nodejs & npm and update the droplet once we've ssh'd into the droplet so its current & up-to-date:</p>
                                            <p style={{ marginTop: 15 }} className="lead">These commands are commonly used in Linux-based systems, particularly those that use the Debian package management system (such as Ubuntu). They are often used in the context of setting up a development environment for Node.js, a popular JavaScript runtime. Here's a breakdown of each command:</p>
                                            <CodeMirror value={`sudo apt update
sudo apt install nodejs
nodejs -v
npm -v`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>sudo apt update:</p>
                                            <ul style={{ marginTop: -22.5 }}>
                                                <li><strong>sudo</strong>: Stands for "superuser do" and is used to execute commands with elevated privileges.</li>
                                                <li><strong>apt</strong>: Stands for Advanced Package Tool, a package management system used in Debian-based distributions..</li>
                                                <li><strong>update</strong>: This command is used to download package information from all configured sources specified in the <em style={{ textDecorationLine: "underline" }}>/etc/apt/sources.list</em> file. It ensures that your system has the latest information about available packages.</li>
                                            </ul>
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>sudo apt install nodejs:</p>
                                            <ul style={{ marginTop: -22.5 }}>
                                                <li><strong>sudo</strong>: As explained before, it grants superuser privileges.</li>
                                                <li><strong>apt</strong>: The package management tool.</li>
                                                <li><strong>install</strong>: Command used to install new packages.</li>
                                                <li><strong>nodejs</strong>: The package name of Node.js.</li>
                                            </ul>
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>nodejs -v:</p>
                                            <ul style={{ marginTop: -22.5 }}>
                                                <li><strong>nodejs</strong>: The command to run the Node.js runtime.</li>
                                                <li><strong>-v</strong>: A command-line option that stands for "version." When used with Node.js, it outputs the installed version of Node.js.</li>
                                            </ul>
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>This command checks and displays the installed version of Node.js on your system.</p>
                                            <p style={{ marginTop: -22.5, fontWeight: "bold" }}>npm -v:</p>
                                            <ul style={{ marginTop: -22.5 }}>
                                                <li><strong>npm</strong>: Node Package Manager, used for managing and installing JavaScript packages.</li>
                                                <li><strong>-v</strong>: Similar to the previous command, this option when used with npm outputs the installed version of npm.</li>
                                            </ul>
                                            <p style={{ marginTop: 15 }}>In summary, these commands are commonly used when setting up a development environment for Node.js on a Debian-based Linux system. They ensure that your system has the latest package information, install Node.js, and then check the installed versions of Node.js and npm.</p>
                                            <hr style={{ borderTop: "2px solid lightgrey" }} />
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>Create a folder for the project on the Droplet.</p>
                                            <CodeMirror value={`mkdir apps
cd apps`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>Clone your project from Github</p>
                                            <CodeMirror value={`git clone yourproject.git`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>Install dependencies and test app</p>
                                            <CodeMirror value={`cd yourproject
npm install
npm start (or whatever your start command)
# stop app
ctrl+C`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>Setup PM2 process manager to keep your app running</p>
                                            <CodeMirror value={`sudo npm i pm2 -g
# Make sure you are in the root folder of your app then run
pm2 start app (or whatever your file name)

# Other pm2 commands
pm2 show app
pm2 status
pm2 restart app
pm2 stop app
pm2 logs (Show log stream)
pm2 flush (Clear logs)

# To make sure app starts when reboot
pm2 startup ubuntu`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />  
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>Now let's setup the droplet <em style={{ textDecorationLine: "underline" }}>firewall</em></p>
                                            <CodeMirror value={`sudo apt install nginx`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />  
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>Now let's setup the droplet <em style={{ textDecorationLine: "underline" }}>nginx configuration</em></p>
                                            <CodeMirror value={`sudo nano /etc/nginx/sites-available/default`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />  
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>Now add this code block and change the corresponding PORT number that your app is running on:</p>
                                            <CodeMirror value={`location / {
        proxy_set_header X-Real-IP $remote_addr;
        proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
        proxy_set_header X-NginX-Proxy true;
        proxy_pass http://localhost:5000;
        proxy_ssl_session_reuse off;
        proxy_set_header Host $http_host;
        proxy_cache_bypass $http_upgrade;
        proxy_redirect off;
}`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>Save and close the file:</p>
                                            <CodeMirror value={`Ctrl + O
Ctrl + X`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />    
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>Test the nginx configuration to ensure the syntax is correct when we added the code above:</p>
                                            <CodeMirror value={`sudo nginx -t`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>Restart the Nginx service for the changes to take effect:</p>
                                            <CodeMirror value={`sudo systemctl restart nginx`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>Install Python NGINX certbot to generate a SSL Certificate:</p>
                                            <CodeMirror value={`sudo add-apt-repository ppa:certbot/certbot
sudo apt-get update
sudo apt-get install python-certbot-nginx
sudo certbot --nginx -d mydomain.com -d www.mydomain.com`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <p style={{ marginTop: 15, fontWeight: "bold" }}>To renew your existing certificate:</p>
                                            <CodeMirror value={`sudo certbot renew --dry-run`} width="100%" extensions={[javascript({ jsx: false })]} onChange={() => {}} />
                                            <p style={{ marginTop: 15 }}>
                                                In conclusion, deploying a MERN (MongoDB, Express.js, React.js, Node.js) stack application to a Digital Ocean droplet is a powerful and rewarding process that allows you to share your web application with the world. Throughout this blog post, we've explored the essential steps involved in this deployment journey.
                                                <br />
                                                <br />
                                                From preparing the server environment with necessary updates using sudo apt update to installing the Node.js runtime and Node Package Manager with sudo apt install nodejs and then verifying their versions with nodejs -v and npm -v, respectively, every step plays a crucial role in ensuring a smooth deployment process.
                                                <br />
                                                <br />
                                                We've also learned about the significance of configuring your MongoDB database and connecting it to your Node.js server, securing the application with HTTPS using a reverse proxy like Nginx, and managing the application's lifecycle with process managers like PM2.
                                                <br />
                                                <br />
                                                As you embark on deploying your MERN stack application to a Digital Ocean droplet, you not only gain valuable experience in server management but also open up new possibilities for scalability and accessibility. Digital Ocean provides an excellent platform for hosting web applications, offering a balance between performance and ease of use.
                                                <br />
                                                <br />
                                                Remember, this deployment process might seem complex initially, but with practice, it becomes a fundamental skill for any web developer. As you celebrate the successful deployment of your MERN stack application, take pride in the fact that your creation is now accessible to users worldwide. Embrace the learning journey, troubleshoot any challenges you encounter, and continue refining your deployment skills as you elevate your web development capabilities. Cheers to your successful deployment on Digital Ocean!
                                            </p>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start BLog Comment Form  */}
                {/* <div className="blog-comment-form pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h3 className="title mb--40 fontWeight500">Leave a Reply</h3>
                                    <form action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Name" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="rnform-group">
                                                    <input type="text" placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12">
                                                <div className="rnform-group">
                                                    <textarea type="text" placeholder="Comment"></textarea>
                                                </div>
                                                
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="blog-btn">
                                                    <Link className="rn-button-style--2 btn-solid" to="/blog-details"><span>SEND MESSAGE</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End BLog Comment Form  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default BlogDetailsDetailsDeployMERNStackDigitalOCeanDroplet;