import React, { Component } from "react";
import PageHelmet from "../../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../component/header/Header";
import Footer from "../../../component/footer/Footer";
import "./styles.css";
import { Link } from "react-router-dom";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class AdTruckPortfolio extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Portfolio Details' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image--1234"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Ad-Truck Logistics - Advertising on Semi-Trucks!</h2>
                                    <p>
                                        Embark on a revolutionary advertising journey with the mobile app I've crafted, redefining brand visibility on a colossal scale through 18-wheeler trailers and similar vehicles. This cutting-edge platform leverages advanced Bluetooth 'view tracking technology,' offering advertisers unparalleled insights into their campaigns. Picture this: detailed analytics tracking the precise number of views as the trailers traverse specific routes, providing real-time performance metrics. Our innovation extends to GPS-based tracking, enabling advertisers to monitor the exact location of their advertisements, ensuring optimal exposure. Engage in dynamic auctions for prime ad spaces on these high-visibility vehicles, fostering a competitive marketplace for brands. Real-time communication features empower advertisers to make instant adjustments based on performance data, maximizing the impact of their campaigns. This app is not just about advertising; it's a transformative approach to out-of-home marketing, blending technology, mobility, and analytics for an unparalleled brand exposure experience. Welcome to the future of on-the-go advertising, where every trailer becomes a mobile canvas for brand storytelling. 🚚📱✨
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Ad-Truck Logistics App</h2>
                                        <p className="subtitle">                                 
                                            Step into the future of advertising with my groundbreaking mobile app, redefining brand promotion through 18-wheeler trailers and similar vehicles. At the core of this innovation lies our proprietary Bluetooth 'view tracking technology,' a sophisticated system that meticulously monitors user engagement with ads in real-time. Picture this: as these massive trailers traverse specific routes, advertisers gain unparalleled insights into the exact number of views, allowing for data-driven decisions to maximize campaign impact.
                                            <br />
                                            <br />
                                            But it doesn't stop there – our platform incorporates GPS-based tracking, offering advertisers the ability to monitor the precise location of their advertisements at any given moment. This level of granular tracking ensures that brands not only reach a vast audience but also do so strategically, enhancing visibility in high-traffic areas and optimizing exposure.
                                            <br />
                                            <br />
                                            Engage in a dynamic marketplace with our auction feature, where ad spaces on these high-impact vehicles are up for competitive bidding. Advertisers can strategically place their brand on trailers following specific routes or during targeted events, ensuring maximum impact for their promotional efforts. This feature transforms the app into a vibrant hub where brands can vie for prime visibility, fostering healthy competition in the advertising landscape.
                                            <br />
                                            <br />
                                            Real-time communication takes center stage, allowing advertisers to make instant adjustments to their campaigns based on performance metrics. Whether it's tweaking creative elements, adjusting targeting parameters, or optimizing routes, this feature ensures that brands have the agility to adapt their strategies on the fly. This level of responsiveness brings a new dimension to out-of-home advertising, making it more dynamic, data-informed, and effective than ever before.
                                            <br />
                                            <br />
                                            In essence, my app represents a holistic approach to on-the-go advertising, seamlessly blending cutting-edge technology, precise analytics, and dynamic communication. It's not just about placing ads on vehicles; it's a transformative journey into the future of marketing, where every trailer becomes a mobile canvas for brand storytelling, creating lasting impressions in the hearts and minds of consumers. 🚚📱✨    
                                        </p>
                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Type</span>
                                                <h4>Full-Stack JavaScript (MERN Stack)</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Types</span>
                                                <h4>Mobile/App</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Technologies</span>
                                                <h4>Express.JS, Node.JS, React-Native, MongoDB, WebSockets, AWS S3, API's/REST, Bluetooth technologies, Etc...</h4>
                                            </div>
                                        </div>

                                        <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val , i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src={require("../../../assets/adtruck1.png")} alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='x5lbckDxfGo' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
{/*                                         
                                        <div className="thumb mb--30">
                                            <img src={require("../../../assets/cyber1.png")}  alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--30">
                                            <img src={require("../../../assets/cyber3.png")}  alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--30">
                                            <img src={require("../../../assets/cyber5.png")}  alt="Portfolio Images"/>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Other Related Work</span>
                                    <h2>More Of My Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <Link to="/portfolio-details-cyber">
                                            <img src={require("../../../assets/cyber1.png")} alt="Portfolio-images"/>
                                        </Link>
                                    </div>
                                    <div className="inner">
                                        <h4><Link to="/portfolio-details-cyber">The Hacker Marketplace</Link></h4>
                                        <span className="category">Web Application - Full-Stack JavaScript</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <Link to="/portfolio-details-makendate">
                                            <img src={require("../../../assets/makendate.png")} alt="Portfolio-images"/>
                                        </Link>
                                    </div>
                                    <div className="inner">
                                        <h4><Link to="/portfolio-details-makendate">MakeNDate Dating App</Link></h4>
                                        <span className="category">Mobile Application - Full-Stack JavaScript</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default AdTruckPortfolio;
