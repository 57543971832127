import React, { Component } from "react";
import PageHelmet from "../../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../../component/header/Header";
import Footer from "../../../component/footer/Footer";
import "./styles.css";
import { Link } from "react-router-dom";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class ClientPortfolioProjectHelper extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Portfolio Details' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image--1234"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Musical Instrument Auction Marketplace Client</h2>
                                    <p>
                                        Embarking on an ambitious journey to revolutionize the way musical instruments are bought and sold, I recently completed a cutting-edge project for an Upwork client: a comprehensive musical instrument auction website. This platform is not just a marketplace but a dynamic auction house where enthusiasts and collectors can engage in real-time bidding wars. I meticulously designed the site to support an array of features including direct bidding, proxy or maximum bidding for those who wish to automate their auction strategy, and a bookmarking system that allows users to keep track of their favorite listings with ease. Understanding the importance of secure transactions, I integrated a robust payment system to facilitate seamless and secure financial exchanges. The website's intuitive interface and backend architecture were crafted to handle a high volume of listings and user activity, ensuring a smooth and enjoyable experience for all users. By merging my technical expertise with an acute understanding of the client's vision, I delivered a bespoke solution that not only meets but exceeds the modern demands of online auctioning for musical instruments.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>MusnIgnite</h2>
                                        <p className="subtitle">
                                            🚀 The application I developed is a comprehensive, feature-rich platform designed to cater to the unique needs of the musical instrument auction market. At its core, the website offers a sophisticated bidding system that enables users to participate in auctions for their desired instruments. This system is enhanced by the inclusion of a max/proxy bid feature, allowing users to set a maximum bid amount for an item. The platform then automatically places bids on their behalf, up to their maximum limit, ensuring they remain competitive in the auction without constant monitoring.
                                            <br />
                                            <br />
                                            💑 To enhance user experience, the website includes a bookmarking feature, enabling users to easily mark and track listings they are interested in. This functionality is crucial for users who wish to follow multiple auctions simultaneously, providing them with a personalized dashboard of their favorite items.
                                            <br />
                                            <br />
                                            🎥 The administrative side of the platform is equally robust. Admins have the authority to remove bids, a necessary feature to maintain the integrity of auctions in case of fraudulent activity or user error. The admin page offers comprehensive tracking of sales and listings, providing valuable insights into the auction dynamics and overall market trends. Admins can also post listings directly from the admin panel, allowing for efficient management of auction items.
                                            <br />
                                            <br />
                                            💬 Security and user convenience are paramount. The application supports a robust "forgot password" recovery feature, ensuring users can quickly regain access to their accounts in case they forget their login credentials. Account creation is streamlined and intuitive, with clear distinctions between the sign-in and sign-up processes, making it easy for new users to join and for returning users to access their accounts.
                                            <br />
                                            <br />
                                            👗 Overall, the application stands as a testament to thoughtful design and development, offering a seamless and secure environment for buying and selling musical instruments through auctions. With its user-friendly interface, comprehensive features, and robust security measures, it provides a reliable and enjoyable platform for auction participants and administrators alike.
                                            <br />✨
                                            </p>
                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Type</span>
                                                <h4>Full-Stack JavaScript (MERN Stack)</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Types</span>
                                                <h4>Website</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Technologies</span>
                                                <h4>Express.JS, Node.JS, Stripe payments, React-Native, MongoDB, WebSockets, AWS S3, API's/REST, RTMP,  Etc...</h4>
                                            </div>
                                        </div>

                                        <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val , i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src={require("../../../assets/demo.png")} alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='d5aTvwDYdcI' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
{/*                                         
                                        <div className="thumb mb--30">
                                            <img src={require("../../../assets/cyber1.png")}  alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--30">
                                            <img src={require("../../../assets/cyber3.png")}  alt="Portfolio Images"/>
                                        </div>

                                        <div className="thumb mb--30">
                                            <img src={require("../../../assets/cyber5.png")}  alt="Portfolio Images"/>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Other Related Work</span>
                                    <h2>More Of My Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <Link to="/portfolio-details-cyber">
                                            <img src={require("../../../assets/cyber1.png")} alt="Portfolio-images"/>
                                        </Link>
                                    </div>
                                    <div className="inner">
                                        <h4><Link to="/portfolio-details-cyber">The Hacker Marketplace</Link></h4>
                                        <span className="category">Web Application - Full-Stack JavaScript</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <Link to="/ad-truck/app/porfolio">
                                            <img src={require("../../../assets/adtruck.png")} alt="Portfolio-images"/>
                                        </Link>
                                    </div>
                                    <div className="inner">
                                        <h4><Link to="/ad-truck/app/porfolio">Ad-Truck Semi-Advertising</Link></h4>
                                        <span className="category">Mobile Application - Full-Stack JavaScript</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                <Footer />  
            </React.Fragment>
        )
    }
}
export default ClientPortfolioProjectHelper;
